<template>
  <div class="privacy-main">
    <authHeader></authHeader>
    <h3 class="text-center mt-5">Privacy Policy</h3>
  </div>
</template>
<script>
export default {
  components: {
    authHeader: () => import("../../main/common/auth_header/auth-header.vue"),
  },
  name: "privacy-policy",
};
</script>
<style scoped></style>
